<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <div class="row">
        <div class="col-md-2 col-lg-2 col-sm-12">
          <side-bar class="d-none d-md-block" />
        </div>

        <div class="col-md-10 col-lg-10 col-sm-12">
          <div class="card position-relative">
            <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
              <div class="anan-tabs__nav">
                <div
                  class="anan-tabs__nav-warp px-2"
                  style="margin-top: 5px;"
                >
                  <div
                    class="anan-tabs__nav-tabs"
                    style="transform: translateX(0px)"
                  >
                    <div
                      class="anan-tabs__nav-tab"
                      :class="{ active: isActive === 'taobao' }"
                      style="white-space: normal"
                      @click="isHidden = false,isActive = 'taobao'"
                    >
                      <span> {{ $t('key-153') }} </span>
                    </div>

                    <div
                      class="anan-tabs__nav-tab"
                      :class="{ active: isActive === 'refund' }"
                      style="white-space: normal"
                      @click="isHidden = true,isActive = 'refund'"
                    >
                      <span> {{ $t('Refunds') }} </span>
                    </div>

                    <!-- <div
                      class="anan-tabs__nav-tab"
                      :class="{ active: isActive === 'balance' }"
                      style="white-space: normal"
                      @click="isHidden = false,isActive = 'balance'"
                    >
                      <span> สมุดบัญชีหยวน </span>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <taobao v-if="isActive === 'taobao'" />
            <refund v-if="isActive === 'refund'" />
            <balance v-if="isActive === 'balance'" />

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import SideBar from '../component/SideBar.vue'
import taobao from './payfor-taobao.vue'
import refund from './payfor-refund.vue'
import balance from './payfor-balance.vue'

export default {
  name: 'LineNotify',
  components: {
    SideBar,
    // ThisHeader,
    taobao,
    refund,
    balance,
  },
  data() {
    return {
      isActive: 'taobao',
      search: '',
    }
  },
  computed: {

  },
  mounted() {
    // this.getData()
  },
  // methods: {
  //   async getData() {
  //     try {
  //       const { data: res } = await this.$http.get('/Payforchina/getPayment')
  //       // this.items = res
  //       // console.log(res)
  //     } catch (err) {
  //       console.log(err)
  //     }
  //   },
  // },
}
</script>

<style lang="scss">

</style>
