<template>
  <div class="col-12">
    <div class="d-flex justify-content-between align-items-center px-1">
      <div>
        <button
          class="btn btn-primary"
          @click="ShowModal()"
        >
          <i class="far fa-repeat mr-25" /> {{ $t('key-148') }}
        </button>
      </div>
      <div class="d-flex align-items-center px-1">
        <div class="anan-input__inner anan-input__inner--normal">
          <input
            v-model="search"
            type="text"
            :placeholder="$t('search')"
            class="anan-input__input"
          >
          <div class="anan-input__suffix">
            <i class="anan-input__clear-btn anan-icon" />
            <i class="anan-input__suffix-icon anan-icon">
              <i class="fal fa-search" />
            </i>
          </div>
        </div>

        <div class="d-flex align-items-center px-1">
          <v-select
            v-model="select"
            label="select"
            :options="select_item"
            variant="outlined"
            style="width: 13em;"
          />

        </div>
      </div>
    </div>

    <div class="p-2">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        show-empty
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner
              label="กำลังโหลด..."
              variant="danger"
            />
          </div>
        </template>

        <template #empty>
          <div class="text-center">
            <img
              src="@/assets/images/anan-img/empty/empty.png"
              alt="empty"
              width="180px"
            >
            <p>No Data</p>
          </div>
        </template>

        <template #cell(number)="data">
          <b-link :to="{name: 'admin-user-type-edit', params: {id: data.item.id}}">
            {{ data.item.itemnumber_text }}
          </b-link>
        </template>

        <template #cell(create_at)="data">
          {{ time(data.item.created_at) }}
        </template>

        <template #cell(type)="data">
          {{ data.item.type_text }}
        </template>

        <template #cell(details)="data">
          {{ data.item.detail }}
        </template>

        <template #cell(amount)="data">
          <span :style="getAmountStyle(data.item.type)">
            {{ getAmountPrefix(data.item.type) }} {{ Commas(data.item.amount) }} ¥
          </span>
          <!-- {{ Commas(data.item.amount) }} ¥ -->
        </template>
        <template #cell(balance)="data">
          ¥ {{ Commas(data.item.balance) }}
        </template>

        <template #cell(status)="data">
          <span
            v-if="data.item.status === 'waiting'"
            class="badge badge-primary badge-pill"
          > {{ $t('pendingReview') }} </span>

          <span
            v-if="data.item.status === 'success'"
            class="badge badge-success badge-pill"
          > {{ $t('operationCompleted') }} </span>
        </template>

        <template #cell(action)="data">
          <feather-icon
            icon="Edit3Icon"
            class="mr-1 text-primary cursor-pointer"
            @click="$router.push({ name: 'admin-transfer-check', params: { id: data.item.id } })"
          />
        </template>
      </b-table>

      <div class="demo-spacing-0 d-flex justify-content-end">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
          @input="GetOrderAll()"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
    <b-modal
      id="modal-money"
      :title="$t('key-148')"
      :ok-title="$t('save')"
      :cancel-title="$t('cancel')"
      @ok.prevent="submitAddNew()"
    >

      <b-form-group
        label-for="h-paecelCode"
        label-cols-md="5"
        class="mb-50"
      >
        <template v-slot:label>
          {{ $t('exRate') }}
        </template>
        <p class="mt-50 font-weight-bolder">
          {{ General.rate_payment }} ฿
        </p>
      </b-form-group>

      <b-form-group
        label-for="h-paecelCode"
        label-cols-md="5"
        class="mb-50"
      >
        <template v-slot:label>
          {{ $t('key-149') }}
        </template>
        <p class="mt-50 font-weight-bolder">
          ¥ {{ Commas(Balance.balance_china) }}
        </p>
      </b-form-group>
      <b-form-group
        label-for="h-paecelCode"
        label-cols-md="5"
        class="mb-50"
      >
        <template v-slot:label>
          {{ $t('key-150') }}
        </template>
        <b-form-input
          v-model="amount"
          type="number"
          class="w-50"
        />
      </b-form-group>

    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BTable, BPagination, BModal, BFormGroup, BFormInput, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import moment from 'moment-timezone'

Vue.component('ValidationProvider', ValidationProvider)

export default {
  name: 'Balance',
  components: {
    BTable,
    BModal,
    BPagination,
    BFormGroup,
    BFormInput,
    vSelect,
    BLink,
  },
  data() {
    return {
      select: 'ทั้งหมด',
      items: [],
      search: null,
      amount: 0,
      rate: 13,
      yuan_point: parseFloat(0.00),
      test: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      Balance: [],
      General: [],
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'number', label: this.$t('number'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'create_at', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'type', label: this.$t('type'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'details', label: this.$t('details'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'amount', label: this.$t('quantity'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'balance', label: this.$t('remainingBalance'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
    select_item() { return [this.$t('all'), this.$t('cancel'), this.$t('pendingReview'), this.$t('operationCompleted')] },
  },
  mounted() {
    this.getData()
    this.getBalance()
    this.getGeneral()
  },
  methods: {
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    async getData() {
      try {
        const { data: res } = await this.$http.get('/Payforchina/getBalanceYuan')
        this.items = res
        // console.log(res)
      } catch (err) {
        console.log(err)
      }
    },
    async getBalance() {
      try {
        const { data: res } = await this.$http.get('/Balance/getbalanceOne')
        this.Balance = res
        // console.log(this.Balance)
      } catch (err) {
        this.SwalError(err)
        console.log(err)
      }
    },
    async getGeneral() {
      try {
        const { data: res } = await this.$http.get('/general/getData')
        if (res.status) {
          this.General = res.data
          // console.log(this.General)
        }
      } catch (err) {
        console.log(err)
        this.SwalError(err)
      }
    },
    ShowModal() {
      this.$bvModal.show('modal-money')
    },
    submitAddNew() {
      if (this.amount >= 1) {
        this.$bvModal.hide('modal-money')
        // this.alert(this.amount)
      }
      return false
    },
    getAmountStyle(amount) {
      if (amount) {
        if (amount && [3, 4, 5, 6, 7, 13, 15, 16, 18, 10, 9].includes(amount)) {
          return { color: 'red' }
        }
        if (amount && [8, 11, 12, 17].includes(amount)) {
          return { color: 'green' }
        }
      }
      return { color: 'orange' }
    },
    getAmountPrefix(value) {
      if (value) {
        if (value && [3, 4, 5, 6, 7, 13, 15, 16, 18, 10, 9].includes(value)) {
          return '-'
        }
        if (value && [8, 11, 12, 17].includes(value)) {
          return '+'
        }
      }
      return ''
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ขออภัยค่ะ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
