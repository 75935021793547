<template>
  <div class="col-12">
    <div class="d-flex justify-content-between align-items-center px-1">
      <div>
        <button
          class="btn btn-primary btn-mobile"
          @click="$router.push({ name: 'payfor-create' })"
        >
          <i class="far fa-plus mr-25" /> {{ $t('addNewItem') }}
        </button>
      </div>
      <div class="d-flex align-items-center px-1">
        <div class="anan-input__inner anan-input__inner--normal">
          <input
            v-model="search"
            type="text"
            :placeholder="$t('search')"
            class="anan-input__input"
            @change="getData(status, search)"
          >
          <div class="anan-input__suffix">
            <i class="anan-input__clear-btn anan-icon" />
            <i class="anan-input__suffix-icon anan-icon">
              <i class="fal fa-search" />
            </i>
          </div>
        </div>

        <div class="d-flex align-items-center px-1">
          <b-form-select
            v-model="select"
            label="value"
            :options="options"
            variant="outlined"
            style="width: 100%;"
            @change="getData(status = select, search)"
          />
        </div>
      </div>
    </div>
    <b-overlay
      :show="showOver"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <div class="p-2">
        <div class="table-container">
          <b-table
            striped
            hover
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            show-empty
            class="table-mobile w-100"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  label="กำลังโหลด..."
                  variant="danger"
                />
              </div>
            </template>

            <template #empty>
              <div class="text-center">
                <img
                  src="@/assets/images/anan-img/empty/empty.png"
                  alt="empty"
                  width="180px"
                >
                <p>No Data</p>
              </div>
            </template>

            <template #cell(number)="data">
              <b-link :to="{ name: 'payfor-edit', params: { id: data.item._id } }">
                {{ data.item.itemnumber_text }}
              </b-link>
            </template>

            <template #cell(created_at)="data">
              {{ time(data.item.created_at) }}
            </template>

            <template #cell(keyword)="data">
              {{ data.item.keyword ? data.item.keyword : '-' }}
            </template>

            <!-- <template #cell(amount)="data">
          {{ Commas(data.item.amount) }}฿
        </template> -->

            <template #cell(type)="data">
              <!-- <span v-if="data.item.payment_type.type_id == 1"> -->
                <!-- {{ $t('key-57') }}  -->
                <!-- {{ data.item.payment_type.type_name }}
              </span>
              <span v-else> -->
                <!-- {{ $t('key-58') }}  -->
                {{ data.item.payment_type.type_name }}
              <!-- </span> -->
            </template>

            <template #cell(amount)="data">
              <div
                v-for="(item, index) in data.item.products"
                :key="index"
              >
                <span v-if="item.status == 1"><i
                  class="fas fa-history text-primary"
                  style="font-size: 12px;"
                /> </span>
                <span v-if="item.status == 2"><i
                  class="fas fa-check text-success"
                  style="font-size: 12px;"
                /></span>
                <span v-if="item.status == 0"><i
                  class="fas fa-ban text-danger"
                  style="font-size: 12px;"
                /></span>
                ¥ {{ item.price }} <br>
              </div>
            </template>

            <template #cell(balance)="data">
              {{ Commas(data.item.pay_products) }} ฿
            </template>

            <template #cell(balance_yuan)="data">
              ¥ {{ Commas(data.item.apply_yuan ? data.item.apply_yuan : 0) }}
            </template>

            <template #cell(status)="data">
              <span
                v-if="data.item.status === 'waiting'"
                class="badge badge-primary badge-pill"
              > {{ $t('pendingReview')
              }} </span>

              <span
                v-if="data.item.status === 'success'"
                class="badge badge-success badge-pill"
              > {{
                $t('operationCompleted') }} </span>
            </template>

            <template #cell(action)="data">
              <feather-icon
                icon="Edit3Icon"
                class="mr-1 text-primary cursor-pointer"
                @click="$router.push({ name: 'payfor-edit', params: { id: data.item._id } })"
              />
            </template>
          </b-table>
        </div>
        <div class="demo-spacing-0 d-flex justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getData(status, search)"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BFormSelect,
  BLink,
  BOverlay,
  BPagination,
  BTable,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
// import vSelect from 'vue-select'

export default {
  name: 'Taobao',
  components: {
    BTable,
    BPagination,
    // vSelect,
    BLink,
    BOverlay,
    BFormSelect,
  },
  data() {
    return {
      select: null,
      items: [],
      search: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      showOver: false,
      status: 0,
    }
  },
  computed: {
    options() {
      return [
        { value: null, text: this.$t('all') },
        { value: 1, text: this.$t('cancel') },
        { value: 2, text: this.$t('pendingReview') },
        { value: 3, text: this.$t('operationCompleted') }]
    },
    fields() {
      return [
        {
          key: 'number', label: this.$t('number'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'created_at', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'keyword', label: this.$t('mnemonic'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'type', label: this.$t('type'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'amount', label: this.$t('yuanAmountDue'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'balance', label: this.$t('useSystemBalance'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData(this.status)
  },
  methods: {
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    async getData(type) {
      this.showOver = true
      const params = {
        type,
        search: this.search,
        page: this.currentPage,
        perPage: this.perPage,
      }
      this.$http.get('/Payforchina/getPaymentCus', { params })
        .then(response => {
          this.items = response.data.data
          this.totalRows = response.data.total
          this.showOver = false
        })
        .catch(error => {
          console.log(error)
          this.showOver = false
          this.SwalError(error)
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@media only screen and (max-width: 600px) {
  .btn-mobile {
    font-size: 11px !important;
    width: 130px;
  }

  .table-mobile {
    width: 1000px !important;
  }
}
</style>
